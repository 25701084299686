import rootStore from "../app/store";

export async function getIdFromOfficeTypeHandbook(displayName: string) {
  const data = rootStore.getState().handbooks.officeType;

  return (data.filter(el => el.display_name == displayName)[0]?.id || displayName);
}

export async function getIdFromOfficeHandbook(displayName: string) {
  const data = rootStore.getState().handbooks.office;

  return (data.filter(el => el.display_name == displayName)[0]?.id || displayName);
}

export async function getIdFromRenterHandbook(displayName: string) {
  const data = rootStore.getState().handbooks.renter;

  return (data.filter(el => el.display_name == displayName)[0]?.id || displayName);
}

export async function getIdFromUserHandbook(displayName: string) {
  const data = rootStore.getState().handbooks.user;

  return (data.filter(el => el.first_name == displayName.split(" ")[0] && el.last_name == displayName.split(" ")[1])[0]?.id || displayName);
}

export async function getIdFromLockHandbook(displayName: string) {
  const data = rootStore.getState().handbooks.lock;

  return (data.filter(el => el.display_name == displayName)[0]?.id || displayName);
}

export async function getIdFromLockSystemHandbook(displayName: string) {
  const data = rootStore.getState().handbooks.lockSystem;

  return (data.filter(el => el.display_name == displayName)[0]?.id || displayName);
}

export async function getIdFromUserRoleHandbook(displayName: string) {
  const data = rootStore.getState().handbooks.userRole;

  return (data.filter(el => el.name == displayName)[0]?.id || displayName);
}

export async function getIdFromLocationHandbook(displayName: string) {
  const data = rootStore.getState().handbooks.location;

  return (data.filter(el => el.display_name == displayName)[0]?.id || displayName);
}

export async function getIdFromCategoryHandbook(displayName: string) {
  const data = rootStore.getState().handbooks.ticketCategory;

  return (data.filter(el => el.display_name == displayName)[0]?.id || displayName);
}

export async function getIdFromRequisitesHandbook(displayName: string) {
  const data = rootStore.getState().handbooks.requisites;

  return (data.filter(el => el.display_name == displayName)[0]?.id || displayName);
}