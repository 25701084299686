import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { LoadingSpinner } from "../../components/loadingSpinner";
import { PageBuilder, ViewPage } from "./pageBuilder";
import { InputField } from "../../components/modals/modalBuilder";
import { api } from "../../../api";
import { useNavigate, useParams } from "react-router-dom";
import { getIdFromLocationHandbook, getIdFromLockHandbook, getIdFromOfficeTypeHandbook } from "../../../utils/getIdFromHandbooks";
import { Office } from "../../../api/offices";
import { Column, ColumnType } from "../tables/tableTypes";

interface IEditOfficeForm {
  display_name: string;
  office_type: number;
  area: number;
  price: number;
  room_count: number;
  floor: number;
  office_lock: number;
  location: number;
}

const editOfficeFields: InputField[] = [
  {
    label: "Название офиса",
    name: "display_name",
    placeholder: "Офис 1",
    type: "text",
    isRequired: true
  }, {
    label: "Тип офиса",
    name: "office_type",
    placeholder: "",
    type: "office-type",
    isRequired: true
  }, {
    label: "Площадь",
    name: "area",
    placeholder: "10м",
    type: "text",
    isRequired: true
  }, {
    label: "Стоимость",
    name: "price",
    placeholder: "90000",
    type: "text",
    isRequired: true
  }, {
    label: "Количество комнат",
    name: "room_count",
    placeholder: "2",
    type: "text",
    isRequired: true
  }, {
    label: "Этаж",
    name: "floor",
    placeholder: "5",
    type: "text",
    isRequired: true
  }, {
    label: "Замок",
    name: "office_lock",
    placeholder: "",
    type: "lock",
    isRequired: true
  }, {
    label: "Локация",
    name: "location",
    placeholder: "",
    type: "location",
    isRequired: true
  }
]

const viewItemColumns: Column[] = [
  {
    key: "display_name",
    label: "Название",
    type: ColumnType.String
  },
  {
    key: "area",
    label: "Площадь",
    type: ColumnType.Number
  },
  {
    key: "price",
    label: "Цена",
    type: ColumnType.Number
  },
  {
    key: "floor",
    label: "Этаж",
    type: ColumnType.Number
  },
  {
    key: "room_count",
    label: "Комнаты",
    type: ColumnType.Number
  },
  {
    key: "office_type",
    label: "Тип офиса",
    type: ColumnType.OfficeType
  },
  {
    key: "location",
    label: "Локация",
    type: ColumnType.Location
  }
];

export function ViewOfficePage() {
  const { id } = useParams();
  const [office, setOffices] = useState<Office | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    api.offices.get(Number(id))
      .then(response => {
        setOffices(response.data);
      })
      .catch(err => {
        console.log(err);
        toast.error("Произошла ошибка при загрузке данных замка!");
      });
  }, [id]);

  const onSubmit = async (data: IEditOfficeForm) => {
    return new Promise<Boolean>(async (resolve, _reject) => {
    const officeType = await getIdFromOfficeTypeHandbook(String(data.office_type));
    const lock = await getIdFromLockHandbook(String(data.office_lock));
    const location = await getIdFromLocationHandbook(String(data.location));
    
    api.offices.update(office!.id, {
      display_name: data.display_name,
      office_type: Number(officeType),
      area: data.area,
      floor: data.floor,
      office_lock: Number(lock),
      price: data.price,
      room_count: data.room_count,
      location: Number(location)
    })
      .then(_data => {
        toast.success("Замок успешно обновлён!");
        resolve(true);
      })
      .catch(err => {
        console.log(err);
        toast.error("Произошла ошибка при редактировании офиса!");
        resolve(false);
      });
    });
  }

  const onDelete = () => {
    return new Promise<Boolean>(async (resolve, _reject) => {
    api.offices.delete(office!.id)
      .then(_data => {
        navigate("/dashboard/offices")
        resolve(true);
      })
      .catch(err => {
        console.log(err);
        toast.error("Произошла ошибка при удалении офиса!");
        resolve(false);
      });
    });
  };

  return (
    <>
      {!location && (
        <LoadingSpinner />
      )}
      {location && (
        <PageBuilder
          title="Офис"
          fields={editOfficeFields}
          onSubmit={onSubmit}
          onDelete={onDelete}
          isSensitiveData={true}
          submitButtonText="Сохранить"
          viewEntityComponent={<ViewPage item={office} columns={viewItemColumns} />}
          defaultValues={{
            display_name: office?.display_name,
            office_type: office?.office_type,
            area: office?.area,
            price: office?.price,
            room_count: office?.room_count,
            floor: office?.floor,
            office_lock: office?.office_lock
          }} />
      )}
    </>
  );
}
