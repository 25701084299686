import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { LoadingSpinner } from "../../components/loadingSpinner";
import { PageBuilder, ViewPage } from "./pageBuilder";
import { InputField } from "../../components/modals/modalBuilder";
import { api } from "../../../api";
import { useNavigate, useParams } from "react-router-dom";
import { getIdFromOfficeHandbook, getIdFromRenterHandbook } from "../../../utils/getIdFromHandbooks";
import { RentedOffice } from "../../../api/rentedOffices";
import { Column, ColumnType } from "../tables/tableTypes";

interface IRentedOfficeEditForm {
  office: number;
  renter: number;
  price: number;
  description: string;
  start_date: string;
  end_date: string;
}

const editRentedOfficeFields: InputField[] = [
  { label: "Офис", name: "office", placeholder: "Офис", type: "office", isRequired: true },
  { label: "Арендатор", name: "renter", placeholder: "Арендатор", type: "renter", isRequired: true },
  { label: "Цена", name: "price", placeholder: "Цена", type: "text", isRequired: true },
  { label: "Описание", name: "description", placeholder: "Описание", type: "text", isRequired: true },
  { label: "Дата начала", name: "start_date", placeholder: "Дата начала", type: "datetime-local", isRequired: true },
  { label: "Дата окончания", name: "end_date", placeholder: "Дата окончания", type: "datetime-local", isRequired: true },
];

const viewItemColumns: Column[] = [
  {
    label: "Офис",
    key: "office",
    type: ColumnType.Office
  },
  {
    label: "Арендатор",
    key: "renter",
    type: ColumnType.Renter
  },
  {
    label: "Цена",
    key: "price",
    type: ColumnType.Number
  },
  {
    label: "Описание",
    key: "description",
    type: ColumnType.String
  },
  {
    label: "Дата начала",
    key: "start_date",
    type: ColumnType.Date
  },
  {
    label: "Дата окончания",
    key: "end_date",
    type: ColumnType.Date
  }
];

export function ViewRentedOfficePage() {
  const { id } = useParams();
  const [office, setOffice] = useState<RentedOffice | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    api.rentedOffices.get(Number(id))
      .then(response => {
        setOffice(response.data);
      })
      .catch(err => {
        console.log(err);
        toast.error("Произошла ошибка при загрузке данных платежа!");
      });
  }, [id]);

  const onSubmit = async (data: IRentedOfficeEditForm) => {
    return new Promise<Boolean>(async (resolve, _reject) => {
      const renter = await getIdFromRenterHandbook(String(data.renter));
      const officeId = await getIdFromOfficeHandbook(String(data.office));

      api.rentedOffices.update(office!.id, {
          office: Number(officeId),
          renter: Number(renter),
          price: data.price,
          description: data.description,
          start_date: data.start_date,
          end_date: data.end_date,
        })
        .then(_data => {
          toast.success("Арендованный офис успешно обновлён!");
          resolve(true);
        })
        .catch(err => {
          console.log(err);
          toast.error("Произошла ошибка при редактировании арендованного офиса!");
          resolve(false);
        });
    });
  }

  const onDelete = () => {
    return new Promise<Boolean>(async (resolve, _reject) => {
      api.rentedOffices.delete(office!.id)
        .then(_data => {
          navigate("/dashboard/offices");
          resolve(true);
        })
        .catch(err => {
          console.log(err);
          toast.error("Произошла ошибка при удалении арендованного офиса!");
          resolve(false);
        });
    });
  };
  return (
    <>
      {!location && (
        <LoadingSpinner />
      )}
      {location && (
        <PageBuilder
          title="Арендованный офис"
          fields={editRentedOfficeFields}
          onSubmit={onSubmit}
          onDelete={onDelete}
          isSensitiveData={true}
          submitButtonText="Сохранить"
          viewEntityComponent={<ViewPage item={office} columns={viewItemColumns} />}
          defaultValues={{
            office: office?.office,
            renter: office?.renter,
            price: office?.price,
            description: office?.description,
            start_date: office?.start_date,
            end_date: office?.end_date
          }} />
      )}
    </>
  );
}
