import { Button, DateRangePicker, Link, Pagination, Spinner, Table, TableBody, TableCell, TableColumn, TableHeader, TableRow } from "@nextui-org/react";
import { useParams } from "react-router-dom";
import { UserHandbook } from "../../../../components/handbooks/userHandbook";
import { useState, useMemo, useEffect, Key } from "react";
import { LockLog } from "../../../../../api/locks";
import { api } from "../../../../../api";
import { toast } from "react-toastify";
import useErrorHandling from "../../../../../hooks/useErrorHandling";
import { getNormalizedDateTime } from "../../../../../utils";
import { UserChip } from "../../../../components/chips/userChip";

function isLog(log: LockLog): log is LockLog {
  return typeof log === 'object' && 'id' in log
};

function renderColumn(item: any, key: Key) {
  switch(key) {
    case 'CREATED_AT':
      return getNormalizedDateTime(item[key]);

    case 'user_id':
      return <UserChip userId={item[key]} />
  }
}

export function LockLogsPage() {
  const { id } = useParams();
  const [page, setPage] = useState(1);
  const rowsPerPage = 10;

  const [data, setData] = useState<LockLog[]>([]);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [total, setTotal] = useState<number | null>(null);
  const handleError = useErrorHandling();

  const pages = useMemo(() => {
    return total ? Math.ceil(total / rowsPerPage) : 0;
  }, [total, rowsPerPage]);

  const loadingState = isLoading || data?.length === 0 ? "loading" : "idle";

  useEffect(() => {
    setLoading(true);
    api.locks.getLogs(Number(id), ((page - 1) * 10))
      .then(res => {
        const nData = [...res.data[0]];
        setTotal(res.data[1]);

        setData(nData);
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
        const { errorMessage } = handleError(err);
        toast.error(errorMessage);
        setLoading(false);
      });
  }, [page]);

  return (
    <>
      <div className="flex flex-row flex-wrap gap-4 w-full">
        <div className="flex flex-col gap-2 max-md:flex-grow">
          <Link href={`/dashboard/locks/${id}`} className="flex flex-row items-center p-4 rounded-xl font-medium text-primary bg-primary bg-opacity-10">
            Журнал действий
          </Link>
          <Link href={`/dashboard/locks/${id}/passcodes`} className="flex flex-row items-center p-4 rounded-xl font-medium text-foreground-600">
            Пароли
          </Link>
        </div>
        <div className="flex-grow flex flex-col gap-2">
          <div className="w-full flex flex-col gap-4 bg-white dark:bg-zinc-900 p-4 rounded-xl border-2 border-foreground-200 dark:border-foreground-100">
            <div className="flex flex-row w-full items-center">
              <div className="flex flex-row justify-between w-full gap-4 items-center">
                <span className="font-medium">
                  Журнал действий
                </span>
              </div>
            </div>
            <div className="flex flex-row gap-2 items-center max-w-fit">
              <UserHandbook
                errorMessage=""
                isInvalid={false}
                //@ts-ignore
                register={{}}
              />
              <DateRangePicker
                label="Выберите отрезок времени"
                variant="bordered"
                onChange={e => console.log(e)}
              />
            </div>
            <div className="flex flex-row gap-2 max-w-fit">
              <Button size="sm" color="primary">Применить фильтры</Button>
              <Button size="sm" variant="flat" color="primary">Сбросить</Button>
            </div>
            <Table
              removeWrapper
              aria-label="Example table with client async pagination"
              bottomContent={
                pages > 0 ? (
                  <div className="flex w-full justify-center">
                    <Pagination
                      isCompact
                      showControls
                      showShadow
                      color="primary"
                      page={page}
                      total={pages}
                      onChange={(page) => setPage(page)}
                    />
                  </div>
                ) : null
              }
            >
              <TableHeader>
                <TableColumn key="CREATED_AT">Дата</TableColumn>
                <TableColumn key="user_id">Пользователь</TableColumn>
              </TableHeader>
              <TableBody
                items={data ?? []}
                loadingContent={<Spinner />}
                loadingState={loadingState}
              >
                {(item) => (
                  <TableRow key={isLog(item) ? item.id : item}>
                    {(columnKey) => <TableCell>{renderColumn(item, columnKey)}</TableCell>}
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
        </div>
      </div>
    </>
  );
}
