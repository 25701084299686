import { Autocomplete, AutocompleteItem, Button, Checkbox, DatePicker, DateValue, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, useDisclosure } from "@nextui-org/react";
import { useState, useEffect } from "react";
import { FaPercent, FaPlus, FaRubleSign } from "react-icons/fa6";
import { RenterHandbook } from "../../handbooks/renterHandbook";
import { useForm, Controller } from "react-hook-form";
import { OfficeHandbook } from "../../handbooks/officeHandbook";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import { api } from "../../../../api";
import { toast } from "react-toastify";
import useErrorHandling from "../../../../hooks/useErrorHandling";
import { getIdFromRenterHandbook, getIdFromRequisitesHandbook } from "../../../../utils/getIdFromHandbooks";
import { RequisitesHandbook } from "../../handbooks/requisitesHandbook";

const months = [
  { key: 'Январь', label: 'Январь' },
  { key: 'Февраль', label: 'Февраль' },
  { key: 'Март', label: 'Март' },
  { key: 'Апрель', label: 'Апрель' },
  { key: 'Май', label: 'Май' },
  { key: 'Июнь', label: 'Июнь' },
  { key: 'Июль', label: 'Июль' },
  { key: 'Август', label: 'Август' },
  { key: 'Сентябрь', label: 'Сентябрь' },
  { key: 'Октябрь', label: 'Октябрь' },
  { key: 'Ноябрь', label: 'Ноябрь' },
  { key: 'Декабрь', label: 'Декабрь' },
];

interface CreateBillForm {
  number: number;
  date: DateValue;
  payer: number;
  provider: number;
  office: number;
  discount: number;
  period: string;
  amount: number;
  paid: boolean;
  description: string;
}

export function CreateBillModal({ disclosure }: { disclosure: ReturnType<typeof useDisclosure> }) {
  const [loading, setLoading] = useState<boolean>(false);
  const [finalAmount, setFinalAmount] = useState<number>(0);
  const [officePrice, setOfficePrice] = useState<number | null>(null);
  const { handleSubmit, register, formState: { errors }, control, watch, reset } = useForm<CreateBillForm>();
  const offices = useAppSelector(state => state.handbooks.office);
  const handleError = useErrorHandling();

  const watchAmount = watch("amount", 0);
  const watchDiscount = watch("discount", 0);

  const calculateFinalAmount = (amount: number, discount: number) => {
    const discountAmount = (amount * discount) / 100;
    const finalSum = amount - discountAmount;
    return finalSum > 0 ? finalSum : 0;
  };

  useEffect(() => {
    setFinalAmount(calculateFinalAmount(watchAmount, watchDiscount));
  }, [watchAmount, watchDiscount]);

  const onHandle = async (data: CreateBillForm) => {
    setLoading(true);
    console.log(data);

    const payer = await getIdFromRenterHandbook(String(data.payer));
    const provider = await getIdFromRequisitesHandbook(String(data.provider));

    api.billings.create({
      amount: data.amount,
      date: `${data.date.year}-${data.date.month}-${data.date.day}`,
      description: data.description,
      discount: data.discount,
      number: data.number,
      paid: data.paid,
      payer: Number(payer),
      period: data.period,
      provider: Number(provider)
    })
      .then(_data => {
        toast.success("Счёт успешно создан!");
        disclosure.onClose();
      })
      .catch(err => {
        console.log(err);
        const { errorMessage } = handleError(err);
        toast.error(errorMessage);
      });
  };

  const onOfficeChange = (office: number) => {
    const foundOffice = offices.find(o => o.id == office);
    console.log(foundOffice);
    if (foundOffice) {
      setOfficePrice(foundOffice.price);
    }
  };

  useEffect(() => {
    reset();
    setOfficePrice(null);
    setFinalAmount(0);
    setLoading(false);
  }, [disclosure.isOpen, reset]);

  return (
    <>
      <Modal isOpen={disclosure.isOpen} onOpenChange={disclosure.onOpenChange} portalContainer={document.getElementById("main")!} backdrop="blur" scrollBehavior="outside">
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader>Создание счёта</ModalHeader>
              <form onSubmit={handleSubmit(onHandle)}>
                <ModalBody className="flex flex-col gap-4">
                  <div className="flex flex-row gap-2">
                    <Input
                      label="Счёт"
                      placeholder="Номер счёта с бухгалтерии"
                      isRequired
                      isInvalid={!!errors["number"]}
                      errorMessage={errors["number"]?.message?.toString() || ""}
                      variant="bordered"
                      {...register("number", { required: true })}
                    />
                  </div>
                  <div className="flex flex-col gap-1">
                    <Controller
                      name="date"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <DatePicker
                          label="Дата платежа"
                          variant="bordered"
                          isRequired
                          onChange={field.onChange}
                          isInvalid={!!errors["date"]}
                          errorMessage={errors["date"]?.message?.toString() || ""}
                        />
                      )}
                    />
                  </div>
                  <div className="flex flex-col gap-1">
                    <Controller
                      name="period"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <Autocomplete
                          label="Оплата за месяц"
                          placeholder="Выберите месяц"
                          variant="bordered"
                          isRequired
                          isInvalid={!!errors["period"]}
                        >
                          {months.map(month => (
                            <AutocompleteItem
                              key={month.key}
                              {...field}
                              onClick={() => field.onChange(month.key)}
                            >
                              {month.label}
                            </AutocompleteItem>
                          ))}
                        </Autocomplete>
                      )}
                    />
                  </div>
                  <div className="flex flex-col gap-1">
                    <RequisitesHandbook
                      register={register("provider", { required: true })}
                      isInvalid={!!errors["provider"]}
                      errorMessage={errors["provider"]?.message?.toString() || ""}
                    />
                  </div>
                  <div className="flex flex-col gap-1">
                    <OfficeHandbook
                      register={register("office", { required: true })}
                      isInvalid={!!errors["office"]}
                      errorMessage={errors["office"]?.message?.toString() || ""}
                      onOfficeChange={onOfficeChange}
                    />
                  </div>
                  <div className="flex flex-col gap-1">
                    <RenterHandbook
                      register={register("payer", { required: true })}
                      isInvalid={!!errors["payer"]}
                      errorMessage={errors["payer"]?.message?.toString() || ""}
                    />
                  </div>
                  <div className="flex flex-row gap-2">
                    <Input
                      className="w-[60%]"
                      label="Сумма"
                      placeholder="Сумма без учёта скидки"
                      isRequired
                      isInvalid={!!errors["amount"]}
                      errorMessage={errors["amount"]?.message?.toString() || ""}
                      variant="bordered"
                      {...register("amount", { required: true })}
                    />
                    <div className="flex flex-row justify-center items-center w-[40%]">
                      {officePrice ?
                        <span>{officePrice.toLocaleString()} ₽</span>
                        : <span className="text-foreground text-opacity-30 text-sm">Выберите офис</span>
                      }
                    </div>
                  </div>
                  <div className="flex flex-row gap-2">
                    <Input
                      label="Скидка"
                      placeholder="Скидка, в процентах"
                      endContent={<FaPercent />}
                      isRequired
                      isInvalid={!!errors["discount"]}
                      errorMessage={errors["discount"]?.message?.toString() || ""}
                      variant="bordered"
                      {...register("discount", { required: true })}
                    />
                  </div>
                  <div className="flex flex-row gap-2">
                    <Input
                      label="Описание"
                      placeholder="Описание к счёту"
                      isRequired
                      isInvalid={!!errors["description"]}
                      errorMessage={errors["description"]?.message?.toString() || ""}
                      variant="bordered"
                      {...register("description", { required: true })}
                    />
                  </div>
                  <div className="flex flex-row gap-2">
                    <Checkbox {...register("paid")}>Оплачено</Checkbox>
                  </div>
                  <div className="flex flex-row gap-2 items-center">
                    <span className="text-foreground text-opacity-50 font-medium">Итоговая сумма:</span>
                    <div className="flex flex-row items-center gap-1">
                      <span className="font-bold text-lg">{finalAmount.toLocaleString()}</span>
                      <FaRubleSign className="text-lg" />
                    </div>
                  </div>
                </ModalBody>
                <ModalFooter>
                  <div className="flex flex-grow flex-row gap-2">
                    <Button color="default" onClick={onClose} variant="flat">Закрыть</Button>
                  </div>
                  <Button color="success" type="submit" variant="flat" startContent={<FaPlus />} isLoading={loading}>Создать</Button>
                </ModalFooter>
              </form>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}