import rootStore from "../../app/store";
import crud, { ApiResponse } from "../crud";

export interface Requisite {
  business_profile: number
  display_name: string
  inn: number
  kpp: number
  ogrn: number
  bank_account: string
  bank_name: string
  bik: string
  correspondent_account: string
  address: string
  phone: string
  email: string
  created_at: string
  id: number
}

export function RequisitesAPI(baseUrl: string) {
  return {
    list: async () => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.get(`${baseUrl}/admin/requisites?business_profile_id=${businessProfileId}`, undefined, true) as ApiResponse<Requisite[]>;
    }
  }
}
