import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  AutocompleteItem,
  Chip,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  Tabs,
} from "@nextui-org/react";
import { TableBuilder } from "../../../components/tables/tableBuilder";
import {
  BarChart,
  Bar,
  Cell,
  PieChart,
  Pie,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { RenterChip } from "../../../components/chips/renterChip";
import { OfficeChip } from "../../../components/chips/officeChip";
import { getCurrentMonth, getMonthInEnglish, getRussianMonthFromEnglish } from "../../../../utils";
import { SummaryReport } from "../../../../api/analytics";
import { api } from "../../../../api";
import { toast } from "react-toastify";
import useErrorHandling from "../../../../hooks/useErrorHandling";
import { ColumnType } from "../../../components/tables/tableTypes";

interface SummaryRow {
  payment_plan: number;
  payment_actual: number;
  deposit: number;
  sale: number;
}

const RADIAN = Math.PI / 180;

interface CustomizedLabelProps {
  cx: number;
  cy: number;
  midAngle: number;
  innerRadius: number;
  outerRadius: number;
  percent: number;
  index: number;
}

const renderCustomizedLabel: React.FC<CustomizedLabelProps> = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      className="font-medium"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

export function FinancePage() {
  const [activeTab, setActiveTab] = useState<string>("");
  const [data, setData] = useState<SummaryReport | null>(null);
  const handleError = useErrorHandling();

  const [summaryRow, setSummaryRow] = useState<SummaryRow | null>(null);

  useEffect(() => {
    setActiveTab(getMonthInEnglish(getCurrentMonth()));
  }, []);

  useEffect(() => {
    if (!activeTab) return;

    api.analytics
      .summary(getRussianMonthFromEnglish(activeTab.toString()))
      .then((res) => {
        const data = res.data;
        setData(data);

        const deposit = res.data.rent_plan.reduce((total, item) => {
          if (item.deposit !== null) {
            return total + item.deposit;
          }
          return total;
        }, 0);

        const payment_plan = res.data.rent_plan.reduce((total, item) => {
          if (item.price !== null) {
            return total + item.price;
          }
          return total;
        }, 0);

        const payment_actual = res.data.actual_payments.reduce((total, item) => {
          if (item.total_paid !== null) {
            return total + item.total_paid;
          }
          return total;
        }, 0);

        const sale = 0;

        setSummaryRow({ deposit, payment_actual, payment_plan, sale });
      })
      .catch((err) => {
        console.log(err);
        const { errorMessage } = handleError(err);
        toast.error(errorMessage);
      });
  }, [activeTab, handleError]);

  const pieData = [
    { name: "Мясницкая", value: 2800000 },
    { name: "Тверская", value: 1200000 },
    { name: "Охотный", value: 5600000 },
  ];

  const barData = [
    {
      name: "Январь",
      uv: 4200,
      pv: 2500,
      amt: 2300,
      qv: 1350,
    },
    {
      name: "Февраль",
      uv: 3200,
      pv: 1400,
      amt: 2200,
      qv: 1450,
    },
    {
      name: "Март",
      uv: 2100,
      pv: 9900,
      amt: 2400,
      qv: 1670,
    },
    {
      name: "Апрель",
      uv: 2600,
      pv: 3700,
      amt: 1950,
      qv: 1200,
    },
    {
      name: "Май",
      uv: 1900,
      pv: 4500,
      amt: 2150,
      qv: 1830,
    },
    {
      name: "Июнь",
      uv: 2400,
      pv: 3850,
      amt: 2450,
      qv: 1100,
    },
    {
      name: "Июль",
      uv: 3500,
      pv: 4200,
      amt: 2050,
      qv: 1760,
    },
    {
      name: "Август",
      uv: 2700,
      pv: 3400,
      amt: 2300,
      qv: 1580,
    },
    {
      name: "Сентябрь",
      uv: 3100,
      pv: 4000,
      amt: 2200,
      qv: 1890,
    },
  ];

  const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col-reverse md:flex-row gap-2">
        <div className="flex flex-col gap-2 md:flex-grow">
          <div className="w-full flex flex-col gap-2 bg-white dark:bg-zinc-900 p-4 rounded-xl border-2 border-foreground-200 dark:border-foreground-100">
            <div className="flex flex-row w-full items-center">
              <span className="font-medium">Топ локаций по аренде</span>
            </div>
            <TableBuilder
              removeWrapper
              displayOptions={false}
              displaySearch={false}
              data={[]}
              columns={[
                {
                  key: "location",
                  label: "Локация",
                  type: ColumnType.Location,
                },
                {
                  key: "planned",
                  label: "План",
                  type: ColumnType.String,
                },
                {
                  key: "paid",
                  label: "Факт",
                  type: ColumnType.String,
                },
                {
                  key: "last_to_pay",
                  label: "Осталось (план)",
                  type: ColumnType.String,
                },
              ]}
            />
          </div>

          <div className="w-full flex flex-col gap-2 bg-white dark:bg-zinc-900 p-4 rounded-xl border-2 border-foreground-200 dark:border-foreground-100">
            <div className="flex flex-row w-full items-center">
              <span className="font-medium">Тенденция аренды за год</span>
            </div>
            <div className="flex flex-row gap-2">
              <Autocomplete label="Год" defaultSelectedKey="1" variant="bordered" size="sm">
                <AutocompleteItem key="1">2024 год</AutocompleteItem>
              </Autocomplete>
            </div>
            <ResponsiveContainer height={250} width="100%">
              <BarChart
                data={barData}
                margin={{
                  top: 20,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip
                  contentStyle={{
                    backgroundColor: "black",
                    color: "white",
                    borderRadius: "12px",
                    border: "none",
                  }}
                />
                <Bar dataKey="pv" stackId="a" fill="#0088FE" />
                <Bar dataKey="uv" stackId="a" fill="#00C49F" />
                <Bar dataKey="amt" stackId="a" fill="#FFBB28" />
                <Bar dataKey="qv" stackId="a" fill="#FF8042" />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>

        <div className="flex flex-col gap-2 md:min-w-96">
          {/* Total Revenue Pie Chart */}
          <div className="w-full flex flex-col gap-2 bg-white dark:bg-zinc-900 p-4 rounded-xl border-2 border-foreground-200 dark:border-foreground-100">
            <div className="flex flex-row w-full items-center">
              <span className="font-medium">Суммарная выручка</span>
            </div>
            <div className="flex flex-col justify-center items-center gap-2">
              <PieChart width={200} height={200}>
                <Pie
                  data={pieData}
                  cx="50%"
                  cy="50%"
                  labelLine={true}
                  label={renderCustomizedLabel}
                  outerRadius={80}
                  fill="#8884d8"
                  dataKey="value"
                >
                  {pieData.map((_entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
              </PieChart>
              <div className="flex flex-row gap-4 flex-wrap">
                {pieData.map((_entry, index) => {
                  const color = COLORS[index % COLORS.length];

                  return (
                    <div key={`legend-${index}`} className="flex flex-row gap-1 items-center justify-center">
                      <div className="w-3 h-3 rounded-full" style={{ backgroundColor: color }} />
                      <span className="font-medium">{_entry.name}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          {/* Potential Earnings Table */}
          <div className="w-full flex flex-col gap-2 bg-white dark:bg-zinc-900 p-4 rounded-xl border-2 border-foreground-200 dark:border-foreground-100">
            <div className="flex flex-row w-full items-center">
              <span className="font-medium">Потенциальная выручка</span>
            </div>
            <div className="flex flex-col justify-center items-center gap-2">
              <TableBuilder
                removeWrapper
                displayOptions={false}
                displaySearch={false}
                columns={[
                  {
                    key: "location_id",
                    type: ColumnType.Location,
                    label: "Локация",
                    sortable: true,
                  },
                  {
                    key: "potential_earnings",
                    type: ColumnType.Custom,
                    label: "Потенциальный доход",
                    sortable: true,
                    render(value: number) {
                      return Number(value).toLocaleString("ru");
                    },
                  },
                ]}
                data={data?.potential_earnings || []}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-2">
        <Tabs variant="light" selectedKey={activeTab} onSelectionChange={e => setActiveTab(e.toString())}>
          <Tab key="january" title="Январь" />
          <Tab key="february" title="Февраль" />
          <Tab key="march" title="Март" />
          <Tab key="april" title="Апрель" />
          <Tab key="may" title="Май" />
          <Tab key="june" title="Июнь" />
          <Tab key="july" title="Июль" />
          <Tab key="august" title="Август" />
          <Tab key="september" title="Сентябрь" />
          <Tab key="october" title="Октябрь" />
          <Tab key="november" title="Ноябрь" />
          <Tab key="december" title="Декабрь" />
        </Tabs>

        <Table isHeaderSticky removeWrapper>
          <TableHeader>
            <TableColumn key="office">Офис</TableColumn>
            <TableColumn key="renter">Арендатор</TableColumn>
            <TableColumn key="payment_plan">
              <div className="flex flex-col">
                <span>План оплаты</span>
                <span className="text-foreground-900">{summaryRow?.payment_plan.toLocaleString("ru") || "0"}</span>
              </div>
            </TableColumn>
            <TableColumn key="planned_payment_date">
              <div className="flex flex-col">
                <span>Плановая дата оплаты</span>
                <span className="text-foreground-900">н/д</span>
              </div>
            </TableColumn>
            <TableColumn key="actual_payment">
              <div className="flex flex-col">
                <span>Фактическая оплата</span>
                <span className="text-foreground-900">{summaryRow?.payment_actual.toLocaleString("ru") || "0"}</span>
              </div>
            </TableColumn>
            <TableColumn key="actual_payment_date">
              <div className="flex flex-col">
                <span>Фактическая дата оплаты</span>
                <span className="text-foreground-900">н/д</span>
              </div>
            </TableColumn>
            <TableColumn key="deposit">
              <div className="flex flex-col">
                <span>Депозит</span>
                <span className="text-foreground-900">{summaryRow?.deposit.toLocaleString("ru") || "0"}</span>
              </div>
            </TableColumn>
            <TableColumn key="discount">
              <div className="flex flex-col">
                <span>Скидка</span>
                <span className="text-foreground-900">{summaryRow?.sale.toLocaleString("ru") || "0"}</span>
              </div>
            </TableColumn>
          </TableHeader>

          <TableBody>
            {data != null ? data.rent_plan.map((row, index) => (
              <TableRow
                key={`row-${row.office_id}`}
                className={`h-16 ${index % 2 === 1 ? "bg-foreground bg-opacity-5" : ""}`}
              >
                <TableCell>
                  <OfficeChip officeId={row.office_id} />
                </TableCell>
                <TableCell>
                  <RenterChip renterId={row.renter_id || 0} />
                </TableCell>
                <TableCell>
                  {row.price.toLocaleString("ru")}
                </TableCell>
                <TableCell>
                  н/д
                </TableCell>
                <TableCell>
                  <Chip variant="dot" color="primary">
                    {data?.actual_payments.find(
                      (el) => el.office_id === row.office_id
                    )?.total_paid?.toLocaleString("ru") || "0"}
                  </Chip>
                </TableCell>
                <TableCell>
                  н/д
                </TableCell>
                <TableCell>
                  {row.deposit.toLocaleString("ru")}
                </TableCell>
                <TableCell>
                  н/д
                </TableCell>
              </TableRow>
            )) : []}
          </TableBody>
        </Table>
      </div>
    </div>
  );
}
