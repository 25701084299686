import { Button, useDisclosure } from "@nextui-org/react";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { TableBuilder } from "../../components/tables/tableBuilder";
import { FaExpand, FaPencil, FaPlus } from "react-icons/fa6";
import { api } from "../../../api";
import { OfficeLocation } from "../../../api/locations";
import { CreateLocationModal } from "../../components/modals/location/createLocationModal";
import { EditLocationModal } from "../../components/modals/location/editLocationModal";
import useErrorHandling from "../../../hooks/useErrorHandling";
import { ColumnType } from "../../components/tables/tableTypes";

export function LocationsPage() {
  const [locations, setLocations] = useState<OfficeLocation[] | null>(null);
  const [location, setLocation] = useState<OfficeLocation | null>(null);

  const handleError = useErrorHandling();

  const createLocationModalDisclosure = useDisclosure();
  const editLocationModalDisclosure = useDisclosure();

  const fullscreenDisclosure = useDisclosure();

  const editLocation = (location: OfficeLocation) => {
    setLocation(location);
    editLocationModalDisclosure.onOpen();
  };

  useEffect(() => {
    api.locations.list()
      .then(async response => {
        setLocations(response.data);
      })
      .catch(err => {
        const { errorMessage } = handleError(err);
        toast.error(errorMessage);
      });
  }, [
    createLocationModalDisclosure.isOpen,
    editLocationModalDisclosure.isOpen
  ]);

  return (
    <>
      <CreateLocationModal disclosure={createLocationModalDisclosure} />
      <EditLocationModal disclosure={editLocationModalDisclosure} location={location} />
      <div className="flex flex-row flex-wrap gap-4 w-full">
        <div className="flex-grow bg-white dark:bg-zinc-900 rounded-xl border-2 border-foreground-200 dark:border-foreground-100 p-4">
          <div className="flex flex-row justify-between w-full items-center mb-4">
            <div className="flex flex-row gap-4 items-center">
              <span className="font-medium">Локации</span>
              <Button variant="flat" size="sm" color="default" onClick={fullscreenDisclosure.onOpen}>
                <FaExpand />
              </Button>
            </div>
            <div className="flex flex-row gap-4 items-center">
              <Button
                startContent={<FaPlus />}
                color="primary"
                variant="solid"
                className="max-w-fit"
                onClick={() => createLocationModalDisclosure.onOpen()}
              >
                Создать локацию
              </Button>
            </div>
          </div>
          <div className="flex flex-col gap-2 mb-2">
            <TableBuilder
              displayOptions={false}
              removeWrapper={true}
              fullscreenDisclosure={fullscreenDisclosure}
              columns={[
                {
                  key: "display_name",
                  label: "Название",
                  type: ColumnType.String,
                  sortable: true
                },
                {
                  key: "city",
                  label: "Город",
                  type: ColumnType.String,
                  sortable: true
                },
                {
                  key: "address",
                  label: "Адрес",
                  type: ColumnType.String,
                  sortable: true
                },
                {
                  key: "district",
                  label: "Район",
                  type: ColumnType.String,
                  sortable: true
                },
                {
                  key: "metro_station",
                  label: "Станция метро",
                  type: ColumnType.String,
                  sortable: true
                },
                {
                  key: "actions",
                  label: "Действия",
                  type: ColumnType.Actions,
                  actions: [
                    {
                      icon: <FaPencil />,
                      onClick: (_value, row) => { editLocation(row) }
                    }
                  ]
                }
              ]}
              data={locations || []}
              rowsPerPage={10}
            />
          </div>
        </div>
      </div>
    </>
  );
}
